import './MainWidget.scss';
import classNames from 'classnames';

import RatingItems from '../RatingItems/RatingItems';
import { ReactComponent as LogoSvg } from './icons/logo.svg';

export interface AppRatingData {
  id: number;
  type: string;
  attributes: {
    averageRating: number;
    countReviews: number;
    scores: {
      1: number;
      2: number;
      3: number;
      4: number;
      5: number;
    };
  };
  appLink: {
    id: number;
    type: string;
    attributes: {
      link: string;
    };
  };
}

export interface WidgetProps {
  theme: 'light' | 'dark';
  rating: AppRatingData;
  isLink?: boolean;
}

const MainWidget = ({ theme, rating, isLink = true }: WidgetProps) => {
  const isDark = theme === 'dark';
  const WrapperComponent = isLink ? 'a' : 'div';

  return (
    <WrapperComponent
      href={isLink ? `https://magic.store/app/${rating.appLink.attributes.link}` : undefined}
      target={isLink ? '_blank' : undefined}
      rel={isLink ? 'noreferrer' : undefined}
      className={classNames('main-widget', {
        light: !isDark,
        dark: isDark,
      })}
    >
      <div className="logo-container">
        <div>
          <LogoSvg className="logo" />
        </div>
        <div
          className={classNames('text-container', {
            light: !isDark,
            dark: isDark,
          })}
        >
          <p className="text-container--sm">Get it on</p>
          <p className="uppercase text-container--md text-container--extrabold">MAGIC STORE</p>
        </div>
      </div>
      <div className="rating-container">
        <p
          className={classNames('average-rating', {
            light: !isDark,
            dark: isDark,
          })}
        >
          Avg. Rating <span className="score">{rating?.attributes?.averageRating?.toFixed(2)}</span>
        </p>
        <div
          className={classNames('rating-items-container', {
            light: !isDark,
            dark: isDark,
          })}
        >
          <RatingItems score={Math.round(rating?.attributes?.averageRating || 0)} />
        </div>
        <p
          className={classNames('reviews', {
            light: !isDark,
            dark: isDark,
          })}
        >
          <span className="review-count">{rating?.attributes?.countReviews}</span> Reviews
        </p>
      </div>
    </WrapperComponent>
  );
};

export default MainWidget;
