import { ComponentPropsWithoutRef, CSSProperties, ReactNode } from 'react';

import classNames from 'classnames';

import TableBody from './TableBody';
import TableHeader from './TableHeader';

interface TableHeaderProps extends ComponentPropsWithoutRef<'div'> {
  children?: ReactNode;
  rows?: Array<Record<string, ReactNode | (() => ReactNode)>> | undefined;
  headings?: { key: string; label: ReactNode }[];
  styleCells?: CSSProperties;
  variant?: 'default' | 'rounded' | 'accordion';
  isStickyHeader?: boolean;
}

// TODO: refactor and improve
const Table = ({
  variant = 'default',
  children,
  rows,
  headings,
  styleCells,
  className,
  isStickyHeader,
  ...props
}: TableHeaderProps) => {
  if (children) {
    return (
      <div className={classNames('w-full', className)} {...props}>
        {children}
      </div>
    );
  }

  return (
    <div className={classNames('w-full', { relative: isStickyHeader }, className)} {...props}>
      <TableHeader
        variant={variant}
        headings={headings}
        styleCells={styleCells}
        isStickyHeader={isStickyHeader}
      />
      <TableBody variant={variant} rows={rows} headings={headings} styleCells={styleCells} />
    </div>
  );
};

export default Table;
