import React, { ComponentPropsWithoutRef, forwardRef } from 'react';

import classNames from 'classnames';

export interface SpinnerProps extends ComponentPropsWithoutRef<'div'> {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: 'primary' | 'secondary' | 'light' | 'disabled';
}

const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(
  ({ className, size = 'lg', color = 'primary' }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames(
          'spinner-loader',
          {
            [`spinner-${size}`]: size,
            [`spinner-color-${color}`]: color,
          },
          className,
        )}
        data-qa="iframe-loader"
      />
    );
  },
);

export default Spinner;
